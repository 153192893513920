import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6439ba08"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["placeholder", "data-id"]
const _hoisted_4 = ["type", "data-id", "placeholder"]
const _hoisted_5 = ["type", "data-id", "placeholder"]
const _hoisted_6 = ["type", "data-id", "placeholder"]
const _hoisted_7 = ["id", "type", "data-id", "placeholder"]
const _hoisted_8 = ["type", "data-id", "placeholder"]
const _hoisted_9 = {
  key: 0,
  class: "drop"
}
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_mask = _resolveDirective("mask")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.input.show)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.input.name), 1))
      : _createCommentVNode("", true),
    (_ctx.input.type === 'textarea')
      ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
          key: 1,
          placeholder: _ctx.input.placeholder,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data[_ctx.input.id]) = $event)),
          "data-id": _ctx.input.id
        }, null, 8, _hoisted_3)), [
          [_vModelText, _ctx.data[_ctx.input.id]]
        ])
      : (_ctx.input.mask)
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 2,
            type: _ctx.input.type,
            ref: _ctx.input.id,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data[_ctx.input.id]) = $event)),
            "data-id": _ctx.input.id,
            placeholder: _ctx.input.placeholder,
            class: _normalizeClass({ error: _ctx.input.error }),
            maxlength: "80"
          }, null, 10, _hoisted_4)), [
            [_directive_mask, _ctx.input.mask],
            [_vModelDynamic, _ctx.data[_ctx.input.id]]
          ])
        : (_ctx.input.formula_element)
          ? _withDirectives((_openBlock(), _createElementBlock("input", {
              key: 3,
              type: _ctx.input.type,
              ref: _ctx.input.id,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data[_ctx.input.id]) = $event)),
              "data-id": _ctx.input.id,
              placeholder: _ctx.input.placeholder,
              class: _normalizeClass({ error: _ctx.input.error }),
              maxlength: "80"
            }, null, 10, _hoisted_5)), [
              [_vModelDynamic, _ctx.data[_ctx.input.id]]
            ])
          : (_ctx.input.drop)
            ? _withDirectives((_openBlock(), _createElementBlock("input", {
                key: 4,
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('toggleDrop', _ctx.input))),
                type: _ctx.input.type,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.data[_ctx.input.id]) = $event)),
                "data-id": _ctx.input.id,
                placeholder: _ctx.input.placeholder,
                class: _normalizeClass({ error: _ctx.input.error, active: _ctx.input.showDrop }),
                maxlength: "80"
              }, null, 10, _hoisted_6)), [
                [_vModelDynamic, _ctx.data[_ctx.input.id]]
              ])
            : (_ctx.input.datepicker)
              ? _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 5,
                  id: _ctx.input.id,
                  type: _ctx.input.type,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.data[_ctx.input.id]) = $event)),
                  "data-id": _ctx.input.id,
                  placeholder: _ctx.input.placeholder,
                  class: _normalizeClass({ error: _ctx.input.error })
                }, null, 10, _hoisted_7)), [
                  [_vModelDynamic, _ctx.data[_ctx.input.id]]
                ])
              : _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 6,
                  type: _ctx.input.type,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.data[_ctx.input.id]) = $event)),
                  "data-id": _ctx.input.id,
                  placeholder: _ctx.input.placeholder,
                  class: _normalizeClass({ error: _ctx.input.error }),
                  maxlength: "80"
                }, null, 10, _hoisted_8)), [
                  [_vModelDynamic, _ctx.data[_ctx.input.id]]
                ]),
    (_ctx.input.drop)
      ? (_openBlock(), _createElementBlock("img", {
          key: 7,
          class: _normalizeClass({ active: _ctx.input.showDrop }),
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('toggleDrop', _ctx.input))),
          src: "/images/common/arrow.svg",
          alt: "arrow"
        }, null, 2))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.input.drop && _ctx.input.showDrop)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("ul", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.input.drop_data, (el) => {
                  return (_openBlock(), _createElementBlock("li", {
                    onClick: ($event: any) => (_ctx.$emit('selectDropElement', el, _ctx.input)),
                    key: el._id
                  }, _toDisplayString(el.name || el.instrument), 9, _hoisted_10))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}