
import { defineComponent, PropType } from "vue-demi";
import { mask } from "vue-the-mask";
import { Instrument } from "@/interfaces/dto/instrument";
import { dynamicsObject } from "@/interfaces";
import AirDatepicker from "air-datepicker";

export default defineComponent({
  name: "Input",
  emits: ["selectDropElement", "toggleDrop"],
  directives: {
    mask,
  },
  props: {
    input: {
      type: Object as PropType<Instrument.Input>,
      required: true,
    },
    data: {
      type: Object as PropType<dynamicsObject>,
      required: true,
    },
    target: Object,
    investments: Array,
  },
  data() {
    return {
      datepicker: {} as AirDatepicker
    }
  },
  mounted() {
    this.datepicker = new AirDatepicker(`#days`, {
      range: true,
      multipleDates: true,
      toggleSelected: false,
      multipleDatesSeparator: ' - ',
      onSelect: ({ date }) => {
        if (Array.isArray(date) && date.length === 2) {
          this.selectedDates(date);
        }
      },
    });
  },
  methods: {
    selectedDates(dates: Date[]) {
      this.datepicker.hide();
      this.generateDate(dates)
    },
    months(month: number) {
      return (
        [
          { id: 0, name: "января" },
          { id: 1, name: "февраля" },
          { id: 2, name: "марта" },
          { id: 3, name: "апреля" },
          { id: 4, name: "мая" },
          { id: 5, name: "июня" },
          { id: 6, name: "июля" },
          { id: 7, name: "августа" },
          { id: 8, name: "сентября" },
          { id: 9, name: "октября" },
          { id: 10, name: "ноября" },
          { id: 11, name: "декабря" },
        ].find((m) => m.id === month)?.name || ""
      );
    },
    generateDate(dates: Date[]) {
      let text = "с FIRST MONTH1 по SECOND MONTH2";
      const firstDate = new Date(dates[0]);
      const secondDate = new Date(dates[1]);
      var daysLag = Math.ceil(
        Math.abs(secondDate.getTime() - firstDate.getTime()) /
          (1000 * 3600 * 24)
      );
      text = text.replace("FIRST", firstDate.getDate().toString());
      text = text.replace("SECOND", secondDate.getDate().toString());
      if (firstDate.getMonth() === secondDate.getMonth()) {
        text = text.replace("MONTH1 ", "");
      } else {
        text = text.replace("MONTH1", this.months(firstDate.getMonth()));
      }
      text = text.replace("MONTH2", this.months(secondDate.getMonth()));
      this.data.dayLength = daysLag + 1;
      this.data.days = text;
    },
  },
});
