
import { defineAsyncComponent, defineComponent } from 'vue-demi';
import { mapGetters } from 'vuex';

import { COURSES_ENUM, ROLES_ENUM } from '@/utils/enums';
import Inputs from '@/components/common/inputs/index.vue';
import { dynamicsObject } from '@/interfaces';
import { PROFILE_INPUTS } from './constants';
import { ERROR_UPLOAD_FILE, ERROR_UPLOAD_FILE_TYPE_IMAGE } from '@/utils/constants';

const courses = defineAsyncComponent(() => import ('./modules/courses.vue'));
const profileComments = defineAsyncComponent(() => import ('./modules/comments.vue'));
const Password = defineAsyncComponent(() => import ('./modules/password.vue'));

export default defineComponent({
  name: 'ProfileMain',
  data() {
    return {
      module: {
        data: {} as dynamicsObject,
        inputs: PROFILE_INPUTS
      },
      preview_avatar: null,
      file: '',
      deleteAvatar: false,
    }
  },
  created() {
    const variables = ['name', 'phone', 'times', 'days', 'dayLength', 'showChat'];
    for (const variable of variables) {
      this.module.data[variable] = this.user[variable];
    }
    if (this.user && this.user.role && (this.user.role === ROLES_ENUM.STUDENT || this.user.role === ROLES_ENUM.SUPPORT)) {
      this.module.inputs = [
        { id: 'name', placeholder: 'ФИО', name: 'ФИО', grid: '1 / 10', type: 'text', drop: false, error: false, required: false, show: true },
        { id: 'phone', placeholder: '+71234567890', name: 'Телефон', mask: '+############', grid: '1 / 10', type: 'phone', drop: false, error: false, required: false, show: true },
      ]
    }
  },
  computed: {
    ...mapGetters(['user', 'Authorization']),
    expert() {
      const user = this.user as dynamicsObject;
      return user && user.role === ROLES_ENUM.EXPERT;
    },
    student() {
      const user = this.user as dynamicsObject;
      return user && user.role === ROLES_ENUM.STUDENT;
    },
    support() {
      const user = this.user as dynamicsObject;
      return user && user.role === ROLES_ENUM.SUPPORT;
    },
    staff() {
      const user = this.user as dynamicsObject;
      return user && user.role === ROLES_ENUM.OWNER || user.accesses.includes(ROLES_ENUM.EXPERT)
    }
  },
  methods: {
    async updateProfile() {
      const result = await this.API.user.updateProfile(this.module.data);
      if (this.file) this.updateAvatar();
      if (this.deleteAvatar) this.removeAvatar();
      this.$store.commit('updateUser', result.data);
    },
    async updateAvatar() {
      const formData = new FormData();
      formData.append("avatar", this.file);
      formData.append("_id", this.user._id);
      const result = await this.API.user.updateAvatar(formData, { _id: this.user._id });
      this.$store.commit('updateUserModel', result.data);
    },
    async removeAvatar() {
      const result = await this.API.user.removeAvatar(this.user._id);
      this.$store.commit('updateUser', result.data);
    },
    onDeleteAvatar() {
      this.deleteAvatar = true;
      this.file = '';
      this.preview_avatar = null;
      this.user.avatar = null;
      (this.$refs.avatar as HTMLInputElement).files = null;
    },
    uploadFile() {
      const file = (this.$refs.avatar as any).files[0];
      this.deleteAvatar = false;
      this.file = file;
      if (!file)
        return this.$store.commit("createNotification", { status: 'error', message: ERROR_UPLOAD_FILE });
      if (!(file.type.includes("jpeg") || file.type.includes("png") || file.type.includes("webp")))
        return this.$store.commit("createNotification", { status: 'error', message: ERROR_UPLOAD_FILE_TYPE_IMAGE });
      
      const img = new Image();
      img.onload = () => {
        let fr = new FileReader();
        fr.onload = (() => {
          return (e: any) => {
            this.preview_avatar = e.target.result;
          };
        })();
        fr.readAsDataURL(file);
      };
      img.src = URL.createObjectURL(file);
    },
  },
  components: {
    Inputs,
    courses,
    profileComments,
    Password
}
})
