
import { defineComponent } from "vue-demi";
import { dynamicsObject } from "@/interfaces";
import { Instrument } from "@/interfaces/dto/instrument";
import Input from "./Input.vue";

export default defineComponent({
  emits: ['amountInflation'],
  name: "Inputs",
  props: {
    module: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      amount: ''
    }
  },
  methods: {
    toggleDrop(input: Instrument.Input) {
      this.module.inputs.forEach((i: Instrument.Input) => {
        if (i.id !== input.id) i.showDrop = false;
      });
      input.showDrop = !input.showDrop;
      if (input.showDrop) {
        this.jq("body").bind("click", (e) => {
          if (
            this.jq(e.target).closest(".drop").length === 0 &&
            this.jq(e.target).siblings(".drop").length === 0
          )
            input.showDrop = false;
        });
      } else {
        this.jq("body").unbind("click");
      }
    },
    selectDropElement(el: dynamicsObject, input: Instrument.Input) {
      this.module.data[input.id] = el.name;
      this.module.data[input.id + "_id"] = el._id;
      input.showDrop = false;
    },
  },
  components: {
    Input,
  },
});
